const TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK =
  "TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK";
const TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK_ALL =
  "TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK_ALL";
const TOGGLE_MONTH_TO_BE_SEARCHED_CHECK = "TOGGLE_MONTH_TO_BE_SEARCHED_CHECK";
const TOGGLE_MONTH_TO_BE_SEARCHED_CHECK_ALL =
  "TOGGLE_MONTH_TO_BE_SEARCHED_CHECK_ALL";
const SET_DATE_LIST = "SET_DATE_LIST";
const TOGGLE_REJECT_TO_BE_SEARCHED_CHECK = "TOGGLE_REJECT_TO_BE_SEARCHED_CHECK";
const SET_FILTERED_YEAR = "SET_FILTERED_YEAR";
const SET_FILTERED_MONTH_TOP = "SET_FILTERED_MONTH_TOP";
const SET_TYPE_IDS = "SET_TYPE_IDS";
const HANDLE_CLEAR_EVENT_SEARCH_TERMS = "HANDLE_CLEAR_EVENT_SEARCH_TERMS";
const SET_IS_SEARCHED_BY_EVENT_TYPE = "SET_IS_SEARCHED_BY_EVENT_TYPE";
const CLEAR_FILTERED = "CLEAR_FILTERED";

export {
  TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK,
  TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK_ALL,
  TOGGLE_MONTH_TO_BE_SEARCHED_CHECK,
  TOGGLE_MONTH_TO_BE_SEARCHED_CHECK_ALL,
  SET_DATE_LIST,
  TOGGLE_REJECT_TO_BE_SEARCHED_CHECK,
  SET_FILTERED_YEAR,
  SET_FILTERED_MONTH_TOP,
  SET_TYPE_IDS,
  HANDLE_CLEAR_EVENT_SEARCH_TERMS,
  SET_IS_SEARCHED_BY_EVENT_TYPE,
  CLEAR_FILTERED,
};
