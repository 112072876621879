const env_manual_url = process.env.REACT_APP_MANUAL_API_URL ?? "";
const env_event_url = process.env.REACT_APP_EVENT_API_URL ?? "";
const env_v2_url = process.env.REACT_APP_COMMON_API_URL ?? ""; //現在仮にtest環境のv1につないでいる
const env_faq_url = process.env.REACT_APP_FAQ_API_URL ?? "";
const env_url = process.env.REACT_APP_COMMON_API_URL ?? "";
const env_camera_url = process.env.REACT_APP_CAMERA_API_URL ?? "";
const env_inquiry_url = process.env.REACT_APP_INQUIRY_API_URL ?? "";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "";

const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI ?? "";

const sentry_dns = process.env.REACT_APP_SENTRY_DNS ?? "";

export {
  env_faq_url,
  env_url,
  env_v2_url,
  env_event_url,
  env_manual_url,
  env_camera_url,
  env_inquiry_url,
  sentry_dns,
  GOOGLE_CLIENT_ID,
  GOOGLE_REDIRECT_URI
};
