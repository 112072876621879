import { GET_MEDIA_FILE_REQUESTED, UPDATE_MEDIA_FILE_REQUESTED } from "./type";

export const getMediaFileRequested = (token: string, id: number) => {
  return {
    type: GET_MEDIA_FILE_REQUESTED,
    token,
    id
  };
};
export const updateMediaFileRequested = (
  token: string,
  id: string,
  payload: {
    tag_ids: number[];
    class_ids: number[];
    facility_ids: number[];
    taken_at: string | null;
  }
) => {
  return {
    type: UPDATE_MEDIA_FILE_REQUESTED,
    token,
    id,
    payload
  };
};
