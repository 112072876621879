import {
  TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK,
  TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK_ALL,
  TOGGLE_MONTH_TO_BE_SEARCHED_CHECK,
  TOGGLE_MONTH_TO_BE_SEARCHED_CHECK_ALL,
  SET_DATE_LIST,
  TOGGLE_REJECT_TO_BE_SEARCHED_CHECK,
  SET_FILTERED_YEAR,
  SET_FILTERED_MONTH_TOP,
  HANDLE_CLEAR_EVENT_SEARCH_TERMS,
  SET_TYPE_IDS,
  SET_IS_SEARCHED_BY_EVENT_TYPE, CLEAR_FILTERED
} from "./type";
// ================================================================= //
// Actions
// ----------------------------------------------------------------- //

export const clearFiltered = () => {
  return {
    type: CLEAR_FILTERED,
  };
};

// クラス
export const toggleClassesToBeSearchedCheck = (classes: string | number) => {
  return {
    type: TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK,
    classes
  };
};

export const toggleClassesToBeSearchedCheckAll = () => {
  return {
    type: TOGGLE_CLASSES_TO_BE_SEARCHED_CHECK_ALL
  };
};

// 月
export const toggleMonthToBeSearchedCheck = (month: string | number) => {
  return {
    type: TOGGLE_MONTH_TO_BE_SEARCHED_CHECK,
    month
  };
};

export const toggleMonthToBeSearchedCheckAll = () => {
  return {
    type: TOGGLE_MONTH_TO_BE_SEARCHED_CHECK_ALL
  };
};

export const setDateList = () => {
  return {
    type: SET_DATE_LIST
  };
};

// 差し戻し
export const toggleRejectToBeSearchedCheck = (filtered_remand: string) => {
  return {
    type: TOGGLE_REJECT_TO_BE_SEARCHED_CHECK,
    filtered_remand
  };
};

// 検索対象の年度を設定
export const setFilteredYear = (filtered_year: number) => {
  return {
    type: SET_FILTERED_YEAR,
    filtered_year
  };
};

// TOPページ内の月指定
export const setFilteredMonthTop = (filtered_month_top: string) => {
  return {
    type: SET_FILTERED_MONTH_TOP,
    filtered_month_top
  };
};

// チェックボックスをリセットする
export const handleClearEventSearchTerms = (target_term: string) => {
  return {
    type: HANDLE_CLEAR_EVENT_SEARCH_TERMS,
    target_term
  };
};

export const setTypeIds = (value: { value: string; label: string }[]) => {
  return {
    type: SET_TYPE_IDS,
    value
  };
};
export const SetIsSearchByEventType = (typeIds: string[]) => {
  return {
    type: SET_IS_SEARCHED_BY_EVENT_TYPE,
    typeIds
  };
};
