import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { color } from "../mixins";
import { GoogleLogin } from "react-google-login";
import { pc } from "../../components/mixins/mediaQuery";
import { GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URI } from "../../serverEnv";
import { googleLoginRequest } from "../../store/session/action";

const SignInGsuite = () => {
  const dispatch = useDispatch();
  const responseGoogle = (response: any) => {
    console.log(response);
    dispatch(googleLoginRequest(response.code));
  };

  console.log("GOOGLE_CLIENT_ID", GOOGLE_CLIENT_ID);
  console.log("GOOGLE_REDIRECT_URI", GOOGLE_REDIRECT_URI);

  return (
    <>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Gsuiteでログイン"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        responseType="code"
        scope="https://www.googleapis.com/auth/admin.directory.user"
        render={renderProps => (
          <GmailLink
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Gsuiteでログイン
          </GmailLink>
        )}
        uxMode="redirect"
        redirectUri={GOOGLE_REDIRECT_URI}
      />
    </>
  );
};

export default SignInGsuite;

const GmailLink = styled.button`
  display: inline-block;
  max-width: 280px;
  width: 100%;
  padding: 11px 0;
  color: ${color.white};
  background-color: ${color.primary};
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 24px;
  ${pc`
    margin-bottom: 30px;
  `}
`;
